<template>
  <div class="moreFile">
    <s-header :hasBack="true" />

    <div class="in main">
      <div class="search">
        <van-search
          v-model="searchValue"
          shape="round"
          placeholder="请输入搜索关键词"
          @search="searchFile"
          @update:model-value="notValue"
        />

        <ul class="searchName">
          <li>
            <span @click="timer('today')">今日</span>
            <span @click="timer('week')">本周</span>
            <span @click="timer('month')">本月</span>
            <span @click="showSelectDate = true">指定日期</span>
          </li>
          <li>
            <span @click="FileType(1)">打印并创建学案</span>
            <span @click="FileType(2)">仅打印</span>
            <span @click="FileType(3)">仅创建学案</span>
          </li>
          <li>
            <span @click="printStatus(2)">已打印</span>
            <span @click="printStatus(1)">打印中</span>
            <span @click="printStatus(3)">待打印</span>
          </li>
          <li>
            <span @click="printStatus(2)">学案已创建</span>
            <span @click="printStatus(2)">学案创建中</span>
          </li>
        </ul>
      </div>

      <h3 class="title">
        <van-icon name="newspaper-o" /><span>文档列表</span>
      </h3>

      <div class="fileList">
        <FileList :list="fileList"></FileList>
      </div>
    </div>
    <van-calendar
      :min-date="minDate"
      v-model:show="showSelectDate"
      type="range"
      @confirm="onConfirm"
      color="#18a4e0"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import sHeader from "@/components/SimpleHeader";
import FileList from "../components/FileList.vue";
import { apiSearchFile } from "@/service/teacher1.4.js";
import { getMyDate } from "@/common/js/utils.js";

onBeforeMount(() => {
  getFileList();
});

let minDate = ref(new Date("2000-01-01"));
let showSelectDate = ref(false);

let searchValue = ref("");
let fileList = ref([]);

const getFileList = async (keyword, startTime, endTime, type, printStatus) => {
  let res = await apiSearchFile({
    keyword,
    startTime,
    endTime,
    type,
    printStatus,
  });
  if (res) {
    res.data.forEach((item) => {
      item.case_info = JSON.parse(item.case_info);
      item.print_setting = JSON.parse(item.print_setting);
      item.time = getMyDate(item.createtime);
    });
  }
  fileList.value = res.data;
};

const searchFile = (value) => {
  getFileList(value);
};
const notValue = (value) => {
  if (!value) {
    getFileList();
  }
};

const onConfirm = async (val) => {
  await getFileList(
    searchValue.value,
    val[0].getTime() / 1000,
    val[1].getTime() / 1000
  );
  showSelectDate.value = false;
};

const timer = async (timerStr) => {
  if (timerStr == "today") {
    let startTime = new Date(new Date().toLocaleDateString()).getTime() / 1000;
    let endTime = Math.floor(
      (new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1) /
        1000
    );
    await getFileList(searchValue.value, startTime, +endTime);
  }
  if (timerStr == "week") {
    let weekStartTime =
      new Date(
        new Date().getTime() -
          3600 *
            1000 *
            24 *
            (new Date().getDay() == 0 ? 6 : new Date().getDay() - 1)
      ).getTime() / 1000;
    let weekEndTimer =
      new Date(
        new Date().getTime() +
          3600 *
            1000 *
            24 *
            (new Date().getDay() == 0 ? 0 : 7 - new Date().getDay())
      ).getTime() / 1000;
    await getFileList(
      searchValue.value,
      Math.floor(weekStartTime),
      Math.floor(weekEndTimer)
    );
  }
  if (timerStr == "month") {
    let mStartTime = getFirstDay();
    let mEndTime = getLastDay();
    await getFileList(
      searchValue.value,
      new Date(mStartTime).getTime() / 1000,
      new Date(mEndTime) / 1000
    );
  }
};

const FileType = async (type) => {
  await getFileList(searchValue.value, "", "", type);
};

const printStatus = async (status) => {
  await getFileList(searchValue.value, "", "", "", status);
};

// 本月第一天
function getFirstDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = "01";
  m = m < 10 ? "0" + m : m; //月份补 0

  return [y, m, d].join("-");
}
// 本月最后一天
function getLastDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = new Date(y, m, 0).getDate(); //获取当月最后一日
  m = m < 10 ? "0" + m : m; //月份补 0
  d = d < 10 ? "0" + d : d; //日数补 0

  return [y, m, d].join("-");
}
</script>

<style lang="less" scoped>
@import "@/common/style/adapting.less";

.search {
  background-color: #fff;
  border-radius: 6px;
  padding-top: 10px;
  box-sizing: border-box;
  .searchName {
    padding: 10px;
    li {
      margin-bottom: 10px;
    }
    span {
      color: #18a4e0;
      border: 1px solid #18a4e0;
      margin-right: 10px;
      padding: 2px 5px;
      border-radius: 2px;
    }
  }
}
.main {
  background-color: rgb(247, 244, 244);
}

.title {
  :deep(.van-icon-newspaper-o) {
    font-size: 12px;
    color: #18a4e0;
  }
}

.fileList {
  background-color: #fff;
  border-radius: 6px;
}
</style>
