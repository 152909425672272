<template>
  <ul class="fileList">
    <li v-for="item in list">
      <div class="top">
        <div class="fileInfo">
          <div>
            <van-image
              width="5vw"
              height="5vw"
              :src="require('@/assets/images/word.png')"
            />
          </div>
          <div>
            <div class="fileTitle">{{ item.file_name }}</div>
            <div>上传时间：{{ item.time }}</div>
          </div>
        </div>
        <div class="again">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
            @click="againPrint(item.identity)"
          >
            <van-image
              width="1.5vw"
              height="1.5vw"
              :src="require('@/assets/images/printer.png')"
            />
            再次打印
          </div>
          <div>打印类型：打印且创建学案</div>
        </div>
      </div>
      <div class="bottom">
        <table>
          <tr>
            <td>纸张尺寸</td>
            <td>打印颜色</td>
            <td>页数</td>
            <td>打印份数</td>
            <td>打印张数</td>
            <td>打印时间</td>
            <td>打印状态</td>
            <td>学案创建状态</td>
          </tr>
          <tr>
            <td>
              {{
                item.print_setting.dmPaperSize == 9
                  ? "A4"
                  : item.print_setting.dmPaperSize == 11
                  ? "A5"
                  : ""
              }}
            </td>
            <td>黑白</td>
            <td>{{ item.print_setting.dmCopies }}</td>
            <td>{{ item.print_setting.dmCopies }}份</td>
            <td>{{ item.print_setting.dmCopies }}张</td>
            <td>{{ item.time }}</td>
            <td>已打印</td>
            <td>已创建</td>
          </tr>
        </table>
      </div>
    </li>
    <li v-if="!list.length" class="noting">
      <van-empty
        :image="require('@/assets/images/notData.png')"
        image-size="100"
        description="暂无学案文档，快去创建一份吧~"
      />
    </li>
  </ul>
</template>

<script setup>
import { defineProps } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { apiAgainPrint } from "@/service/teacher1.4.js";

defineProps(["list"]);
const router = useRouter();

const againPrint = async (id) => {
  let res = await apiAgainPrint({ taskId: id });
  if (res) {
    // router.push({ path: "/teacher/printFile/creating" });
    Toast.success("打印成功");
  }
};
</script>

<style lang="less" scoped>
.fileList {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  li {
    margin-bottom: 5px;
    background-color: rgb(247, 244, 244);
    padding: 8px 8px 8px 5px;
    border-radius: 6px;
  }
  .noting {
    background-color: #fff;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .fileInfo {
      display: flex;
      .fileTitle {
        line-height: 20px;
      }
    }
    .again {
      div:nth-child(1) {
        text-align: right;
        line-height: 20px;
        color: #18a4e0;
      }
    }
  }
  .bottom {
    border-top: 1px solid #d7d7d7;
    padding-top: 5px;
    table {
      width: 100%;
      text-align: center;
      span {
        color: #18a4e0;
      }
    }
  }
}
</style>
