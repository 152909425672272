<template>
  <div class="headBox">
    <header class="simple-header van-hairline--bottom">
      <van-icon
        name="arrow-left"
        v-if="hasBack"
        @click="goBack"
        size="20px"
        style="margin-top: 10px"
      />
      <i v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
      <div
        class="simple-header-content"
        :style="[
          { left: hasBack ? '' : '10px' },
          { right: actions.length < 1 ? '10px' : '' },
        ]"
      >
        <div class="logo" v-if="showLogo">
          <van-image
            fit="contain"
            height="100%"
            :src="require('@/assets/images/logo.png')"
          />
        </div>
        <div
          class="title"
          :style="[{ left: hasBack ? '' : 'calc(20vw + 10px)' }]"
        >
          {{ title }}
        </div>
        <!-- <div class="timer" v-if="showTimer">{{timer.weekStr}} {{timer.mdhmFormat}}</div> -->
      </div>
      <div class="content-block" />
      <i
        v-if="showMore && actions.length > 0"
        class="nbicon nbmore"
        @click="doMore"
      ></i>
      <i v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
    </header>
    <div class="block" />
    <van-action-sheet
      :round="false"
      v-model:show="showAction"
      :actions="actions"
      cancel-text="取消"
      @select="onActionSelect"
      @cancel="showAction = false"
    />

    <!-- <van-dialog v-model:show="showChangePW" title="标题" :show-confirm-button="false">
        <div class="form">
          <van-form @submit="changePassword">
            <van-field
              v-model="password"
              type="password"
              name="password"
              label="原密码"
              placeholder="原密码"
              :rules="[{ required: true, message: '请填写原密码' }]"
            />
            <van-field
              v-model="password2"
              type="password"
              name="password2"
              label="新密码"
              placeholder="新密码"
              :rules="[{ required: true, message: '请填写新密码' }]"
            />
            <van-field
              v-model="password2Confirm"
              type="password"
              name="password2Confirm"
              label="确认密码"
              placeholder="确认密码"
              :rules="[{ required: true, message: '请填写确认密码' }]"
            />
            <div style="margin: 16px;">
              <van-button round block color="#1baeae" native-type="submit"
                >确认修改</van-button
              >
            </div>
            <div style="margin: 0 16px 16px;">
              <van-button round block @click="showChangePW = false"
                >取消</van-button
              >
            </div>
          </van-form>
        </div>
    </van-dialog> -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { Dialog, Toast } from "vant";
import { clearLocal } from "@/common/js/utils";
import { apiChangePassword } from "@/service/user";
// import { logout } from "@/service/user"
export default {
  props: {
    hasBack: {
      type: Boolean,
      default: false,
    },
    back: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    // showTimer: {
    // 	type: Boolean,
    // 	default: false,
    // },
    showMore: {
      type: Boolean,
      default: false,
    },
    moreAction: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["callback", "moreback"],
  setup(props, ctx) {
    const router = useRouter();

    const goBack = () => {
      if (!props.back) {
        router.go(-1);
      } else {
        router.push({ path: props.back });
      }
      ctx.emit("callback");
    };

    const defaultActions = [
      {
        name: "修改密码",
        type: "changePassword",
      },
      {
        name: "退出登录",
        color: "#ee0a24",
        type: "logout",
      },
    ];

    const state = reactive({
      title: props.title,
      showAction: false,
      showChangePW: false,
      password: "",
      password2: "",
      password2Confirm: "",
      actions: [...props.moreAction, ...defaultActions],
      // timer: {}
    });

    // setInterval(() => {
    // 	state.timer = dateStrToObj()
    // }, 1000)

    const doMore = () => {
      state.showAction = true;
    };

    const onActionSelect = (item) => {
      state.showAction = false;
      if (item.type == "logout") {
        toLogout();
      }
      if (item.type == "changePassword") {
        state.showChangePW = true;
      }
      ctx.emit("moreback", item);
    };

    const toLogout = () => {
      Dialog.confirm({
        title: "确认退出登录？",
        message: "退出成功后，返回登录页面！",
      })
        .then(async () => {
          // const apiRes = await logout({});
          // if(apiRes){
          clearLocal();
          router.push({
            name: "login",
          });
          // }
        })
        .catch(() => {});
    };

    const changePassword = async (values) => {
      if (values.password2Confirm != values.password2) {
        Toast.fail("确认密码与新密码不一致！");
        return false;
      }

      const apiRes = await apiChangePassword(values);
      if (apiRes) {
        Toast.success("修改成功，请重新登录！");
        clearLocal();
        router.push({
          name: "login",
        });
      }
    };
    return {
      ...toRefs(state),
      goBack,
      doMore,
      onActionSelect,
      changePassword,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../common/style/mixin";

.simple-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .fj();
  .wh(100%, 30px);
  line-height: 30px;
  padding: 0 10px;
  .boxSizing();
  color: #252525;
  background: #fff;
  .content-block {
    font-size: 14px;
  }

  .simple-header-content {
    position: absolute;
    top: 5px;
    left: 50px;
    right: 50px;
    height: 20px;
    .logo {
      height: 20px;
      width: 20vw;
      overflow: hidden;
      float: left;
    }
    .title {
      position: absolute;
      top: 0;
      left: 20%;
      width: 60%;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .timer {
      height: 20px;
      line-height: 20px;
      font-size: 8px;
      float: right;
    }
  }
}
.block {
  height: 30px;
}
</style>
<style>
.headBox .van-overlay {
  z-index: 99;
}

.headBox .Overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}

.headBox .Overlay .form {
  padding: 10px 10px 0;
  border-radius: 8px;
  background-color: #fff;
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .headBox .van-overlay {
    top: 30px;
    bottom: 40px;
    height: calc(100vh - 30px - 40px) !important;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .headBox .van-overlay {
    top: 30px;
    bottom: 20px;
    left: 50px;
    height: calc(100vh - 30px - 20px) !important;
    width: calc(100vw - 50px) !important;
  }
}
</style>
