import request from '@/utils/request'
import requestForm from '@/utils/requestForm'

export function getUserInfo() {
  return request.get('/h5/user/info');
}

export function login(params) {
  // return requestForm.post('/v1/learning/auth/login', params);
  return requestForm.post('/h5/user/checkIn', params);
}

export function apiChangePassword(params) {
  return requestForm.post('/h5/user/changePassword', params);
}

export function logout(params) {
  return requestForm.post('/h5/user/logout', params)
}